<template>
  <v-autocomplete
    :value="value"
    label="Cidade"
    :items="cities"
    item-text="name"
    item-value="id"
    :loading="loading"
    :disabled="loading"
    :error-messages="errorMessages"
    autocomplete="nope"
    hide-details="auto"
    @input="(e) => $emit('input', e)"
  />
</template>

<script>
  import citiesApi from '@/api/cities'

  export default {
    props: {
      value: {
        type: Number,
        default: 0,
      },
      stateId: {
        type: Number,
        default: 0,
      },
      errorMessages: {
        type: Array,
        default: () => {},
      },
    },

    data () {
      return {
        cities: [],
        loading: false,
      }
    },

    watch: {
      stateId (val) {
        if (!val) return

        this.loadCities()
      },
    },

    mounted () {
      if (this.stateId) {
        this.loadCities()
      }
    },

    methods: {

      async loadCities () {
        try {
          this.loading = true

          const response = await citiesApi.list(this.stateId)

          this.cities = response.data.cities
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loading = false
        }
      },

    },
  }
</script>
